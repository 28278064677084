import React from 'react';


const Loading = () => {
    return (
        <progress class="progress w-48 bg-primary text-white h-4 mt-4"></progress>

    );
};

export default Loading;