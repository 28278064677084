import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Feedback = () => {
    const location = useLocation();


    const [feedbackk, SetFeedback] = useState([]);

    // console.log(feedbackk);


    const test = "MDcwNDE1MDI1MzAyMS42ODMwMDIwMDcxMDQyMjUuUFIxNjU2OTI1Mzc1NjUxNy5hNDUyMjBjMTg3ODBiYjc4ZDUzOA=="

    const url = window.location.href;

    // console.log(url);


    var str = url;
    var array = str.split("&", 5);
    const ref = array[2]
    // console.log(ref);
    // console.log(array);
    var t = ref;

    var y = t.split("payment_ref_id=", 2);
    const reff = y[1]
    // console.log(reff);




    const feedback = () => {

        fetch(`https://sandbox.mynagad.com:10080/remote-payment-gateway-1.0/api/dfs/verify/payment/${reff}`)
            .then(Response => Response.json())
            .then(data => console.log(data))


        // fetch("http://202.164.213.67/payment/daily/sb_total.php?FROM_DATE=2022-06-14&&TOO_DATE=2022-06-14")
        // fetch(`http://sandbox.mynagad.com:10080/remote-payment-gateway-1.0/api/dfs/verify/payment/${reff}`)
        //     .then(Response => Response.json())
        //     .then(data => console.log(data))


        // useEffect(() => {

        //     axios.get(`http://202.164.213.67/payment/daily/sb_total.php?FROM_DATE=2022-06-14&&TOO_DATE=2022-06-14`)
        //         .then(Response => Response.json())
        //         .then(data => console.log(data))


        // });

    }

    return (
        <div>
            <h3> {url}</h3> <br />
            <p>{reff}</p>

            <button className='btn btn-success btn-sm rounded text-white font-bold' onClick={feedback}>Submit</button>
        </div>
    );
};

export default Feedback;