import React from 'react';

const BkashFeedback = () => {




    return (
        <div>
            <h2>Feedback for bkash</h2>




        </div>
    );
};

export default BkashFeedback;